app.directive('productItem', [
  '$uibModal',
  '$window',
  'productService',
  'cartService',
  'trackerService',
  '$q',
  '$http',
  '$rootScope',
  '$timeout',
  '$location',
  '$uibModalStack',
  'cartTypes',
  'modalTypes',
  'slFeatureService',
  'fbService',
  'productSetService',
  '$cookies',
  'productPreorderService',
  function (
    $uibModal,
    $window,
    productService,
    cartService,
    trackerService,
    $q,
    $http,
    $rootScope,
    $timeout,
    $location,
    $uibModalStack,
    cartTypes,
    modalTypes,
    slFeatureService,
    fbService,
    productSetService,
    $cookies,
    productPreorderService,
  ) {
    return {
      restrict: 'E',
      scope: true,
      link: function (scope, element, attrs) {
        scope.productId = attrs.productId;
        scope.modalOpened = false;
        scope.product = null; // note that this cannot be {} as it would break if(scope.product) checking in gallery/var. dropdown

        const isShopBuilderPreview = () => {
          return $location.search().is_preview === '2';
        };

        element.on('click', '.btn-add-to-cart:not(.quick-cart-item)', function (
          $event,
        ) {
          // opened from PLP variant selector
          var variantId = $(this).attr('data-variant-id');
          // if class 'btn-add-to-cart' and 'quick-cart-item' both exist, should not popup instant add to cart dialog
          $event.preventDefault();
          if (
            $(this).is(
              '.quick-cart-item, .available-time-disabled, .not-in-available-time',
            )
          )
            return;
          if (!scope.modalOpened) {
            scope.modalOpened = true;
            scope.quickAdd(variantId);
          }
        });

        element.on(
          'click',
          '.btn-add-to-promotion-cart:not(.quick-cart-item)',
          function ($event) {
            // opened from PLP variant selector
            var variantId = $(this).attr('data-variant-id');
            $event.preventDefault();
            if (scope.modalOpened || $(this).hasClass('not-in-available-time'))
              return;
            scope.modalOpened = true;
            scope
              .getProduct()
              .then(function (res) {
                var product = res.data.data;
                scope.product = product; // for memorization

                if (
                  (product.variations && product.variations.length > 0) ||
                  productSetService.isProductSetEnabled(product)
                ) {
                  showProductModal(
                    product,
                    { cartType: cartTypes.PROMOTION },
                    variantId,
                  );
                } else if (
                  productSetService.isProductSetRevampEnabled(product)
                ) {
                  if (isShopBuilderPreview()) {
                    return;
                  }
                  $cookies.put('productSetFromPromotionPage', product._id);
                  $cookies.put(
                    'productSetFromPromotionPageId',
                    scope.promotion._id,
                  );
                  window.location = `/products/${product._id}`;
                } else {
                  scope.modalOpened = false;
                  scope.addItemToCart(product, cartTypes.PROMOTION, {
                    skip_calculate_order: false,
                  });
                }
              })
              .catch(function () {
                scope.modalOpened = false;
              });
          },
        );

        var shouldSetPriceLabelEllipsis = element.find(
          '.price-sale:contains("~")',
        ).length;
        if (shouldSetPriceLabelEllipsis) {
          var setPriceLabelEllipsis = _.debounce(function () {
            element.find('.price-sale').dotdotdot({
              wrap: 'letter',
              ellipsis: '...',
              height: 44,
            });
          }, 200);
          setPriceLabelEllipsis();
          scope.$on('window.resize', function () {
            setPriceLabelEllipsis();
          });
        }

        function showProductModal(product, config, variantId) {
          var hasStock = config && (config.hasStock || false);
          var cartType = config && (config.cartType || '');
          var url = '/products/' + product._id + '?quick_cart=true';
          var newScope = scope.$new();
          newScope.modalType = modalTypes.QUICK_CART;

          var urlSearchParams = $location.search();
          if (urlSearchParams.draft) {
            url += '&draft=true';
          }

          newScope.product = product;
          newScope.variantId = variantId;
          if (
            hasStock == false ||
            (hasStock == null &&
              product &&
              product.quantity == 0 &&
              !product.unlimited_quantity)
          ) {
            // notEnoughStock takes number of stock
            newScope.notEnoughStock = 0;
          }

          switch (cartType) {
            case cartTypes.PROMOTION:
              url += '&promotion_cart=true';
              break;
          }

          $http({
            method: 'GET',
            url: url,
            headers: { 'X-Requested-With': 'XMLHttpRequest' },
          })
            .then(function (res) {
              var modal = $uibModal.open({
                template: res.data,
                controller: 'productQuickCartController',
                scope: newScope,
                windowClass:
                  'QuickCart-modal ' + (attrs.modalWindowClass || ''),
              });

              modal.opened.then(function () {
                $rootScope.$emit('modal.open', {
                  modalType: modalTypes.QUICK_CART,
                });
                fbService.hideFacebookCustomerChat();
                function addScrollBar() {
                  $('.modal-content').overlayScrollbars({
                    overflowBehavior: {
                      x: 'hidden',
                    },
                    callbacks: {
                      onInitialized: function () {
                        // avoid scrolling junk
                        $('.quick-cart-cancel-holder').prependTo(
                          $('.QuickCart-modal'),
                        );
                      },
                    },
                  });
                }

                if (window.matchMedia('(max-width: 767px)').matches) {
                  $timeout(function () {
                    addScrollBar();
                    $('.Util-cssToggleLabel:visible').on('click', function () {
                      $timeout(addScrollBar, 200);
                    });
                  }, 200);
                }

                // handle the scroll bar of quick-cart in desktop
                if (window.matchMedia('(min-width: 768px)').matches) {
                  $timeout(function () {
                    $('.quick-cart-body').overlayScrollbars({
                      overflowBehavior: {
                        x: 'hidden',
                      },
                      callbacks: {
                        onContentSizeChanged: function () {
                          $('.quick-cart-body').attr(
                            'style',
                            'overflow: auto !important;',
                          );
                        },
                      },
                    });
                    $('.Util-cssToggleLabel:visible').on('click', function () {
                      $timeout(function () {
                        $('.quick-cart-body').overlayScrollbars({
                          overflowBehavior: {
                            x: 'hidden',
                          },
                          callbacks: {
                            onContentSizeChanged: function () {
                              $('.quick-cart-body').attr(
                                'style',
                                'overflow: auto !important;',
                              );
                            },
                          },
                        });
                      }, 200);
                    });
                  }, 200);
                }
              });

              // when quick cart modal close
              modal.result.then(function () {
                $rootScope.$emit('modal.close', {
                  modalType: modalTypes.QUICK_CART,
                  productId: scope.productId,
                });
                fbService.hideFacebookCustomerChat();
              });

              return modal;
            })
            .finally(function () {
              scope.modalOpened = false;
            });
        }

        // returns a promise containing the product
        // TODO: Servce quick cart UI directly from ruby
        scope.getProduct = function () {
          return $q(function (resolve) {
            if (_.isEmpty(scope.product)) {
              // fetch product from api
              resolve(productService.getById(scope.productId));
            } else {
              // use already fetched product
              // simulate http payload by wrapping 2 layers of 'data'
              resolve({ data: { data: scope.product } });
            }
          });
        };

        scope.quickAdd = function (variantId) {
          // TODO: reuse common calls
          if (window.matchMedia('(max-width: 991px)').matches) {
            // mobile: always show modal and lazy load product to speed up response
            scope.getProduct().then(
              function (response) {
                var product = response.data.data;
                scope.product = product; // for memorization
                if (productSetService.isProductSetRevampEnabled(product)) {
                  if (!isShopBuilderPreview()) {
                    window.location = `/products/${product._id}`;
                  }
                  return;
                }
                scope.$broadcast('quick_cart.product.loaded', product);
                showProductModal(scope.product, null, variantId);
              },
              function (error) {
                scope.modalOpened = false;
                // eslint-disable-next-line no-undef
                reject(error);
              },
            );
          } else {
            // desktop: fetch product first, show modal only when there's variations
            scope
              .getProduct()
              .then(function (response) {
                var product = response.data.data;
                scope.product = product; // for memorization
                if (
                  (product.variations && product.variations.length > 0) ||
                  productSetService.isProductSetEnabled(product)
                ) {
                  showProductModal(product, null, variantId);
                } else if (
                  productSetService.isProductSetRevampEnabled(product)
                ) {
                  if (!isShopBuilderPreview()) {
                    window.location = `/products/${product._id}`;
                  }
                  return;
                } else {
                  scope.modalOpened = false;
                  scope.addItemToCart(product);
                }
              })
              .catch(function (error) {
                scope.modalOpened = false;
                // eslint-disable-next-line no-undef
                reject(error);
              });
          }
        };

        scope.addItemToCart = function (
          product,
          cartType,
          cartOptions = { skip_calculate_order: true },
        ) {
          productService.checkStock(product._id).then(function (data) {
            data = data.data;
            const preorderLimit = productPreorderService.getPreorderLimit(data);
            var hasStock =
              data.unlimited_quantity ||
              productService.isOutOfStockOrderable(data) ||
              1 + data.cart_quantity <= data.quantity + preorderLimit;
            var cartItemData = {
              quantity: 1,
              variation: null,
              blacklisted_payment_option_ids:
                product.blacklisted_payment_option_ids,
              blacklisted_delivery_option_ids:
                product.blacklisted_delivery_option_ids,
            };
            if (
              hasStock &&
              productService.validateCheckoutReady(
                product,
                false,
                cartService.getItemPrice({ product: product }),
              )
            ) {
              //add item to cart
              if (product.subscription_enabled) {
                cartItemData['type'] = 'subscription_product';
              }
              const dollars = cartService.getItemPrice({ product: product })
                .dollars;
              cartItemData.value = dollars;
              cartService
                .addItem(product._id, cartItemData, null, cartOptions)
                .then(function (data) {
                  if (slFeatureService.hasFeature('data_layer_info')) {
                    $rootScope.$emit(
                      'add.item.to.cart',
                      cartService.getGaItemData({
                        product: product,
                        variant: null,
                      }),
                    );
                  }
                  trackerService.track({
                    type: trackerService.generalEventType.ADD_TO_CART,
                    data: {
                      items: [
                        {
                          product,
                          value: dollars,
                          quantity: 1,
                          variationSelected: null,
                        },
                      ],
                      eventId: data.event_id,
                    },
                  });

                  switch (cartType) {
                    case cartTypes.PROMOTION:
                      break;
                    default:
                      jQuery.sidr('open', 'cart-panel');
                  }
                });
            } else {
              // not enough stock to complete add-to-cart
              // Show modal to show out of stock message
              showProductModal(product, {
                hasStock: hasStock,
                cartType: cartType,
              });
            }
          });
        };

        $uibModalStack.dismiss = function (modalInstance) {
          modalInstance.close();
        };

        scope.$on('window.resize', function () {
          scope.$digest();
        });

        scope.isMobileTabletWidth = function () {
          return window.matchMedia('(max-width: 991px)').matches;
        };
      },
    };
  },
]);
