import { isEmpty, isNil, omitBy, sumBy } from 'lodash-es';

app.service('trackerService', [
  '$rootScope',
  '$location',
  'mainConfig',
  '$filter',
  'Analytics',
  'productService',
  'fbPixelSettings',
  'gaService',
  'slFeatureService',
  'cookieConsentService',
  '$http',
  function (
    $rootScope,
    $location,
    mainConfig,
    $filter,
    Analytics,
    productService,
    fbPixelSettings,
    gaService,
    slFeatureService,
    cookieConsentService,
    $http,
  ) {
    const userActionTypes = {
      CHECKOUT: 'Checkout',
      ADD_ITEM: 'AddItem',
      UPDATE_ITEM: 'UpdateItem',
      REMOVE_ITEM: 'RemoveItem',
    };

    var fbContentType = function (fbPixelSetting) {
      return fbPixelSetting && fbPixelSetting.single_variation
        ? 'product_group'
        : 'product';
    };

    var trackerFilters = {
      RecommendItem: function (props) {
        return {
          product_id: props.product_id,
          based_product_id: props.based_product_id,
          rank: props.rank,
        };
      },
      ProductClick: function (props) {
        return {
          page_type: props.page_type,
          product_id: props.product_id,
        };
      },
      ViewRelatedProducts: function (props) {
        return {
          product_infos: props.product_infos,
        };
      },
      WishlistAdd: function (props) {
        return {
          product_id: props.product_id,
        };
      },
      WishlistRemove: function (props) {
        return {
          product_id: props.product_id,
        };
      },
      ProductImpression: function (props) {
        return {
          page_type: props.page_type,
          product_ids: props.product_ids,
        };
      },
    };

    var getUniqueId = function () {
      var unique_id = window.localStorage.getItem('shoplytics_unique_id');
      return unique_id;
    };

    const fbInitiateCheckout = function ({ items, subtotal, eventID }) {
      if (!eventID) return;
      try {
        // facebook tracking pixel Standard Event: initiateCheckout
        if (subtotal === undefined || subtotal.currency_iso === '') return;

        const eventName = 'InitiateCheckout';

        fbq(
          'track',
          eventName,
          {
            content_type: fbContentType(fbPixelSettings[0]),
            content_ids: _.map(items, function (item) {
              return productService.getSku(
                item.product_id,
                item.product.sku,
                item.variation,
                fbPixelSettings[0],
              );
            }),
            num_items: sumBy(items, 'quantity'),
            value: subtotal.dollars || 0,
            currency: subtotal.currency_iso,
          },
          {
            eventID,
          },
        );
      } catch (e) {
        // facebook tracking pixel doesn't exist
      }
    };

    const getAIData = (action, eventCategory) => {
      const validAIDataTypes = [
        userActionTypes.ADD_ITEM,
        userActionTypes.UPDATE_ITEM,
        userActionTypes.REMOVE_ITEM,
      ];

      if (!validAIDataTypes.includes(action) || eventCategory !== 'Cart') {
        return undefined;
      }

      const aiDataFromUrl = $location.search().ai_data;

      if (!isEmpty(aiDataFromUrl)) {
        return aiDataFromUrl;
      }

      const referrerSearchUrl = document.referrer.split('?')[1] || '';
      const urlSearch = new URLSearchParams(referrerSearchUrl);
      const aiDataFromReferrer = urlSearch.get('ai_data');

      if (!isEmpty(aiDataFromReferrer)) {
        return aiDataFromReferrer;
      }

      return undefined;
    };

    const generalEventType = {
      REGISTRATION: 'TRACK.REGISTRATION',
      SEARCH: 'TRACK.SEARCH',
      VIEW_CONTENT: 'TRACK.VIEW_CONTENT',
      ADD_TO_CART: 'TRACK.ADD_TO_CART',
      REMOVE_FROM_CART: 'TRACK.REMOVE_FROM_CART',
      START_CHECKOUT: 'TRACK.START_CHECKOUT',
      INITIATE_CHECKOUT: 'TRACK.INITIATE_CHECKOUT',
      ADD_SHIPPING: 'TRACK.ADD_SHIPPING',
      ADD_BILLING: 'TRACK.ADD_BILLING',
      PURCHASE: 'TRACK.PURCHASE',
      LOAD_CART_PAGE: 'TRACK.LOAD_CART_PAGE',
      LOAD_CHECKOUT_PAGE: 'TRACK.LOAD_CHECKOUT_PAGE',
      PRODUCT_CLICK: 'TRACK.PRODUCT_CLICK',
      ADD_TO_WISHLIST: 'TRACK.ADD_TO_WISHLIST',
      REMOVE_FROM_WISHLIST: 'TRACK.REMOVE_FROM_WISHLIST',
    };

    const mapEventType = (eventName) => {
      switch (eventName) {
        case 'ProductClick':
          return generalEventType.PRODUCT_CLICK;
        case 'WishlistAdd':
          return generalEventType.ADD_TO_WISHLIST;
        case 'WishlistRemove':
          return generalEventType.REMOVE_FROM_WISHLIST;
        default:
          return null;
      }
    };

    $rootScope.$on(generalEventType.ADD_TO_CART, (e, { items, eventId }) => {
      try {
        const totalValue = items.reduce(
          (accumulator, item) => accumulator + (item.value || 0),
          0,
        );
        // facebook tracking pixel Standard Event: addToCart
        fbq(
          'track',
          'AddToCart',
          {
            content_name: $filter('translateModel')(
              items[0].product.title_translations,
            ),
            content_ids: items.map((item) =>
              productService.getSku(
                item.product._id,
                item.product.sku,
                item.variationSelected,
                fbPixelSettings[0],
              ),
            ),
            content_type: fbContentType(fbPixelSettings[0]),
            value: totalValue,
            currency: mainConfig.merchantData.base_currency_code,
          },
          {
            eventID: eventId,
          },
        );
      } catch (e) {
        // facebook tracking pixel doesn't exist
      }
    });

    return {
      track: function (event, props) {
        if (typeof event !== 'string') {
          $rootScope.$broadcast(event.type, event.data);
          return;
        } else {
          const eventType = mapEventType(event);
          if (eventType !== null) {
            $rootScope.$broadcast(eventType, props);
          }
        }
        var userId = mainConfig.currentUser
          ? mainConfig.currentUser['_id']
          : null;
        var filter = trackerFilters[event];
        if (typeof filter === 'function') {
          props = typeof props === 'object' ? filter(props) : {};
          if (slFeatureService.hasFeature('shoplytics_to_hd')) {
            Object.assign(props, { tracker_hd: true });
          }

          var trackData = {
            user_id: userId,
            data: JSON.stringify(props),
          };

          if (slFeatureService.hasFeature('cross_shop_tracker')) {
            Object.assign(trackData, { unique_id: getUniqueId() });
          }

          // Shoplytics tracker will only be loaded after obtaining user consent
          if (typeof shoplytics === 'function') {
            shoplytics('track', event, trackData);
          }
        } else {
          console.warn('Event "' + event + '" does not exist.');
        }
      },
      shouldSendDefaultPageView: function () {
        var specificPage = [
          'products-show',
          'orders-confirm',
          'checkout-index',
          'fast_checkout-index',
        ];
        return !_.include(specificPage, mainConfig.pageType);
      },
      pageView: function (props = {}) {
        const { data: propsData = {}, ...restProps } = props;
        const { 'Product ID': product_id, 'Category ID': category_id } =
          mainConfig.trackerEventData ?? {};
        const pageViewData = {
          ...restProps,
          user_id: mainConfig.currentUser?._id,
          product_id,
          ...(slFeatureService.hasFeature('cross_shop_tracker') && {
            unique_id: getUniqueId(),
          }),
          data: JSON.stringify({
            ...propsData,
            product_id,
            category_id,
            ...(slFeatureService.hasFeature('shoplytics_to_hd') && {
              tracker_hd: true,
            }),
          }),
        };

        // Shoplytics tracker will only be loaded after obtaining user consent
        if (typeof shoplytics === 'function') {
          shoplytics('track', 'PageView', pageViewData);
        }
      },
      gaSendDetails: function (product, variation, related_products) {
        // Analytics.addProduct(productId, name, category, brand, variant, price, quantity, coupon, position);
        if (Analytics.configuration.enhancedEcommerce) {
          gaService.setUserId();
          if (related_products != null) {
            var ga_temp_variation = null;
            for (var index = related_products.length - 1; index >= 0; --index) {
              ga_temp_variation = null;
              if (
                related_products[index]['variations'] != null &&
                related_products[index]['variations'].length > 0
              ) {
                ga_temp_variation = related_products[index]['variations'][0];
              }
              // values marked "" are either for phase2, or not marked as required on GA examples
              Analytics.addImpression(
                productService.getSku(
                  related_products[index]['id'],
                  related_products[index]['sku'],
                  ga_temp_variation,
                ),
                $filter('translateModel')(
                  related_products[index]['title_translations'],
                ),
                '',
                '',
                '',
                productService.getVariationName(ga_temp_variation),
                '0',
                '',
              );
            }
          }
          Analytics.addProduct(
            productService.getSku(product._id, product.sku, variation),
            $filter('translateModel')(product.title_translations),
            '',
            '',
            productService.getVariationName(variation),
            '',
            '',
            '',
            '0',
          );
          Analytics.trackDetail();
        }
      },

      fbSendViewContent: async function (product, value, variationSelected) {
        try {
          // facebook tracking pixel Standard Event: ViewContent
          var eventId = [
            'ViewContent',
            new Date().getTime(),
            mainConfig.currentUser
              ? mainConfig.currentUser._id
              : nanoidNonSecure(),
          ].join('.');

          if (slFeatureService.hasFeature('fb_ssapi')) {
            const trackingData = {
              event_id: eventId,
              fbc: cookieConsentService.getConsentedCookie('marketing', '_fbc'),
              fbp: cookieConsentService.getConsentedCookie('marketing', '_fbp'),
              product_id: product._id,
              product_sku: product.sku,
              product_name: $filter('translateModel')(
                product.title_translations,
              ),
              variation_key: variationSelected?.key,
              variation_sku: variationSelected?.sku,
              value,
              external_id: mainConfig.currentUser?._id,
              fn: mainConfig.currentUser?.name
                ? forge_sha256(mainConfig.currentUser.name)
                : undefined,
              em: mainConfig.currentUser?.email
                ? forge_sha256(mainConfig.currentUser.email)
                : undefined,
              ph: mainConfig.currentUser?.phones[0]
                ? forge_sha256(mainConfig.currentUser.phones[0])
                : undefined,
              event_source_url: window.location.href,
            };

            $http.get('/api/track/fb_view_content', {
              params: omitBy(trackingData, isNil),
            });
          }

          fbq(
            'track',
            'ViewContent',
            {
              content_type: fbContentType(fbPixelSettings[0]),
              content_ids: [
                productService.getSku(
                  product._id,
                  product.sku,
                  variationSelected,
                  fbPixelSettings[0],
                ),
              ],
              content_name: $filter('translateModel')(
                product.title_translations,
              ),
              currency: mainConfig.merchantData.base_currency_code,
              value: value,
            },
            {
              eventID: eventId,
            },
          );
        } catch (e) {
          // facebook pixel does not exist
        }
      },

      addToWishlist: function (product, value, variationSelected) {
        try {
          // facebook tracking pixel Standard Event: AddToWishlist
          fbq('track', 'AddToWishlist', {
            value: value,
            currency: mainConfig.merchantData.base_currency_code,
            content_name: $filter('translateModel')(product.title_translations),
            content_ids: [
              productService.getSku(
                product._id,
                product.sku,
                variationSelected,
                fbPixelSettings[0],
              ),
            ],
            content_type: fbContentType(fbPixelSettings[0]),
          });
        } catch (e) {
          // facebook tracking pixel doesn't exist
        }
        this.track('WishlistAdd', {
          product_id: productService.getSku(
            product._id,
            product.sku,
            variationSelected,
          ),
          value,
        });
      },

      removeFromWishlist: function (product) {
        this.track('WishlistRemove', {
          product_id: product._id,
        });
      },

      fbPurchase: function (order) {
        try {
          // facebook tracking pixel Standard Event: purchase
          fbq(
            'track',
            'Purchase',
            {
              content_type: fbContentType(fbPixelSettings[0]),
              content_ids: _.map(order.subtotal_items, function (item) {
                return productService.getSku(
                  item.object_data._id,
                  item.object_data.sku,
                  item.item_data.variation_data,
                  fbPixelSettings[0],
                );
              }),
              num_items: sumBy(order.subtotal_items, 'quantity'),
              value: order.total.dollars,
              currency: order.total.currency_iso,
              order_id: order.order_number,
            },
            {
              eventID: 'Purchase.' + order._id,
            },
          );
        } catch (e) {
          // facebook tracking pixel doesn't exist
        }
      },

      fbInitiateCheckout: function (items, subtotal) {
        const eventID =
          'InitiateCheckout' +
          '.' +
          String(Math.floor(Math.random() * 100000 + 1)) +
          '.' +
          String(Math.floor(Date.now()));
        cookieConsentService.putConsentedCookie(
          'marketing',
          'fb_initiate_checkout_event_id',
          eventID,
        );

        fbInitiateCheckout({ items, subtotal, eventID });
      },

      fbInitiateCheckoutForCartLink: function (items, subtotal) {
        const eventID = cookieConsentService.getConsentedCookie(
          'marketing',
          'cart_link_fb_initiate_checkout_event_id',
        );
        fbInitiateCheckout({ items, subtotal, eventID });

        cookieConsentService.removeConsentedCookie(
          'marketing',
          'cart_link_fb_initiate_checkout_event_id',
        );
      },
      fbAddPaymentInfo: function (items, total) {
        try {
          // facebook tracking pixel Standard Event: AddPaymentInfo
          fbq('track', 'AddPaymentInfo', {
            content_type: fbContentType(fbPixelSettings[0]),
            content_ids: _.map(items, function (item) {
              return productService.getSku(
                item.product_id,
                item.product.sku,
                item.variation,
                fbPixelSettings[0],
              );
            }),
            num_items: sumBy(items, 'quantity'),
            value: total.dollars,
            currency: total.currency_iso,
          });
        } catch (e) {
          // facebook tracking pixel doesn't exist
        }
      },
      userAction: function (action, eventCategory, options) {
        options = options || {};
        var getValue = function (key) {
          var value = options[key];
          return value !== undefined ? value : null;
        };
        var productId =
          getValue('productId') || mainConfig.trackerEventData['Product ID'];
        var eventData = {
          event_name: action,
          event_category: eventCategory,
          user_id: mainConfig.currentUser
            ? mainConfig.currentUser['_id']
            : null,
          product_id: productId, // Can be removed after the event tracker has finished refactoring
          variation_id: getValue('variationId'), // Can be removed after the event tracker has finished refactoring
          quantity: getValue('quantity'), // Can be removed after the event tracker has finished refactoring
          data: JSON.stringify({
            type: getValue('type'),
            product_id: productId,
            variation_id: getValue('variationId'),
            quantity: getValue('quantity'),
            price: getValue('price'),
            ai_data: getAIData(action, eventCategory),
            tracker_hd: slFeatureService.hasFeature('shoplytics_to_hd') || null,
          }),
        };

        if (slFeatureService.hasFeature('cross_shop_tracker')) {
          Object.assign(eventData, { unique_id: getUniqueId() });
        }

        // Shoplytics tracker will only be loaded after obtaining user consent
        if (typeof shoplytics === 'function') {
          shoplytics('track', action, eventData);
        }
      },
      fbSignup: function (id) {
        try {
          //facebook tracking pixel Standard Event: Lead
          fbq(
            'track',
            'Lead',
            {
              content_name: id,
            },
            {
              eventID: 'Lead.' + id,
            },
          );
        } catch (e) {
          // facebook tracking pixel doesn't exist
        }
      },
      generalEventType,
      userActionTypes,
    };
  },
]);
